<template>
    <div>
        <a-card title="Карточка диалога" style="width: 100%">
            <table class="table">
                <tbody>
                    <tr>
                        <th class="h6">ID</th>
                        <th class="h6">Группа</th>
                        <th class="h6">Категория</th>
                        <th class="h6">Дата создания</th>
                        <th class="h6">Дата изменения</th>
                    </tr>
                    <tr>
                        <td>{{ String(ticket.ID).padStart(6, '0') }}</td>
                        <td>{{ groups.get(ticket.User.GroupIdx) }}</td>
                        <td>Вопросы</td>
                        <td>{{ new Date(ticket.CreatedAt).toLocaleDateString() }}</td>
                        <td>{{ new Date(ticket.UpdatedAt).toLocaleDateString() }}</td>
                    </tr>
                    <tr>
                        <td colspan="6"></td>
                    </tr>
                    <tr>
                        <th class="h6">ФИО</th>
                        <td>{{ ticket.User.FullName }}</td>
                        <th class="h6">Telegram UID</th>
                        <td colspan="3">{{ ticket.User.TelegramUid }}</td>
                    </tr>
                </tbody>
            </table>
        </a-card>
        <a-card title="Сообщения" style="width: 100%;">
            <template>
                <ul>
                    <li v-for="item in ticket.Tickets" :key="item.ID">
                        <!-- User question -->
                        <a-comment>
                            <template #actions>
                                <span v-if="(item.Status === 0)" key="comment-nested-reply-to"
                                    @click="toggleReplyForm(item.ID)">⤵ Ответить</span>
                                <span v-if="(item.Status === 0)" key="comment-nested-reply-to1"
                                    @click="handleMarkAsRead(item)">✔ Пометить как
                                    выполенное</span>
                                <span v-if="(item.Status === 1)" key="comment-nested-reply-to2"
                                    @click="handleMarkAsRead(item)">✉️ Пометить как
                                    невыполненное</span>
                            </template>
                            <template #author>
                                <a>{{ ticket.User.FullName }}</a>
                            </template>
                            <template #avatar>
                                <a-avatar src="images/img-user.png" />
                            </template>
                            <template #content>
                                <p>
                                    {{ item.Description }}
                                </p>
                            </template>

                            <!-- Reply form -->
                            <!-- <a-comment v-if="item.Status === 0"> -->
                            <a-comment v-if="(isReplyingTo === item.ID)" class="replyform"
                                :class="{ open: isReplyingTo === item.ID }">
                                <template #avatar>
                                    <a-avatar src="images/info-card-1.jpg" alt="Администратор" />
                                </template>
                                <template #content>
                                    <a-form-item>
                                        <a-textarea :rows="4" v-model:value="value" placeholder="Ваш ответ..." />
                                    </a-form-item>
                                    <a-form-item>
                                        <a-button html-type="submit" :loading="submitting" type="primary"
                                            @click="handleSubmit(item)">
                                            Добавить комментарий
                                        </a-button>
                                    </a-form-item>
                                </template>
                            </a-comment>

                            <!-- Admin reply -->
                            <a-comment v-if="item.Status === 1">
                                <template #author>
                                    <a>Администратор</a>
                                </template>
                                <template #avatar>
                                    <a-avatar src="images/info-card-1.jpg" />
                                </template>
                                <template #content>
                                    <p>
                                        {{ item.Answer }}
                                    </p>
                                </template>
                            </a-comment>
                        </a-comment>
                    </li>
                </ul>
            </template>

            <!--             <a-list class="comment-list" :header="`${ticket.Tickets.length} сообщений`" item-layout="horizontal"
                :data-source="ticket.Tickets">
                <template v-slot:renderItem="{ item }">
                    <a-list-item>
                        <a-comment :author="ticket.User.FullName" :content="item.Description" :datetime="item.CreatedAt">
                        </a-comment>
                    </a-list-item>
                </template> 
            </a-list> -->
        </a-card>
        <!-- NOTE: test for debugging
        <div v-for="(comment, index) in comments" :key="index">
            <p>{{ comment.author }}</p>
            <p>{{ comment.content }}</p>
        </div> -->
        <!--         <a-card v-if="ticket.Status != 1" title="Форма отправки сообщения пользователю" style="width: 100%">
            <p>Отправляет сообщение пользователю в любом случае. Для ответов лучше использовать кнопку ответить в чате выше- там используется функионал ответа на сообшение.</p>
            <a-comment>
                <template #content>
                    <a-form-item>
                        <a-textarea v-model:value="value" :rows="4" />
                    </a-form-item>
                    <a-form-item>
                        <a-button html-type="submit" :loading="submitting" type="primary" @click="handleSubmit">
                            Add Comment
                        </a-button>
                    </a-form-item>
                </template>
            </a-comment>
        </a-card> -->
    </div>
</template>

<script lang="js">
import { Comment } from 'ant-design-vue';
import axios from 'axios';
import '../scss/pages/_ticket.scss';

export default {
    components: {
        AComment: Comment,
    },
    data() {
        return {
            isReplyingTo: null, // id of the comment being replied to
            submitting: false,
            value: '',
            ticket: { User: {}, },
            groups: new Map([
                [1, "Системный аналитик"],
                [2, "Таможня"],
                [3, "Большие данные"],
                [4, "Машинное обучение"],
                [5, "Системный аналитик (гостеприимство)"],
                [6, "Не обучается на цифровой кафедре"],
            ]),
            status_txt: new Array("Не отвечено", "Отвечено"),
            status_style: new Array(
                "question",
                "answer",
            ),
        };
    },
    mounted() {
        // search for get request
        var tid = this.$route.query.tid;
        this.fetchData(tid);
    },
    methods: {
        toggleReplyForm(commentId) {
            this.isReplyingTo = this.isReplyingTo === commentId ? null : commentId;
        },

        async handleMarkAsRead(item) {
            try {
                let status_ = item.Status;
                status_ ^= 1;
                let prequest = {
                    TicketId: item.ID,
                    Status: status_,
                }
                const response = await axios.post("api/v1/markticketasread", prequest);
                //console.log(response.data);
                item.Status = status_;
            }
            catch (error) {
                this.submitting = false;
                console.log(error);
            }
        },
        async handleSubmit(item) {
            if (!this.value) {
                return;
            }

            this.submitting = true;
            try {
                //console.log(this.ticket);
                let prequest = {
                    AnswerText: this.value,
                    TicketId: item.ID, // NOTE: try with lowercase d for golang BindJson
                }
                const response = await axios.post("api/v1/answerticket", prequest);
                this.submitting = false;
                //console.log(response.data);
                this.value = '';
                item.Status = 1
                item.Answer = prequest.AnswerText;
                this.toggleReplyForm(item.ID);
            }
            catch (error) {
                this.submitting = false;
                console.log(error);
            }
            /* NOTE: testing code
            setTimeout(() => {
                this.submitting = false;
                this.comments = [
                    {
                        author: 'Han Solo',
                        content: this.value,
                    },
                    ...this.comments,
                ];
                console.log(this.comments);
                this.value = '';
            }, 1000);
            */
        },
        fetchData(tid) {
            axios.get("api/v1/ticket?tid=" + tid).then((response) => {
                this.ticket = response.data; // want the only result
            });
        },
    }
}
</script>

<style lang="scss">
.ant-comment-content-author,
.ant-comment-content-detail,
.ant-comment {
    font-weight: 550; // Set your desired font size
}

.replyform {
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    max-height: 0;
    overflow: hidden;
    opacity: 0;

    &.open {
        max-height: 500px;
        opacity: 1;
    }
}
</style>
